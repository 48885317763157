import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";
import MaintenanceOverdueDaysForm from "@/forms/MaintenanceOverdueDaysForm";

const Dashboard = lazy(() =>
  import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Customer")
);
const Admin = lazy(() =>
  import(/*webpackChunkName:'AdminPage'*/ "@/pages/Admin")
);

const Customer = lazy(() =>
  import(/*webpackChunkName:'CustomerPage'*/ "@/pages/Customer")
);

const SimCard = lazy(() =>
  import(/*webpackChunkName:'SimCardPage'*/ "@/pages/SimCard")
);

const Device = lazy(() =>
  import(/*webpackChunkName:'DevicePage'*/ "@/pages/Device")
);

const Vehicle = lazy(() =>
  import(/*webpackChunkName:'VehiclePage'*/ "@/pages/Vehicle")
);

const DailyScore = lazy(() =>
  import(/*webpackChunkName:'DailyScorePage'*/ "@/pages/DailyScore")
);

const LifeTimeScore = lazy(() =>
  import(/*webpackChunkName:'LifeTimeScorePage'*/ "@/pages/LifeTimeScore")
);

const DailyDistance = lazy(() =>
  import(/*webpackChunkName:'DailyDistancePage'*/ "@/pages/DailyDistance")
);

const DailyTime = lazy(() =>
  import(/*webpackChunkName:'DailyTimePage'*/ "@/pages/DailyTime")
);

const DailyIdle = lazy(() =>
  import(/*webpackChunkName:'DailyIdlePage'*/ "@/pages/DailyIdle")
);

const MaintenanceOverdueDays = lazy(() =>
  import(
    /*webpackChunkName:'MaintenanceOverdueDaysPage'*/ "@/pages/MaintenanceOverdueDays"
  )
);

const MaintenanceOverdueKm = lazy(() =>
  import(
    /*webpackChunkName:'MaintenanceOverdueKmPage'*/ "@/pages/MaintenanceOverdueKm"
  )
);

const DTCActive = lazy(() =>
  import(/*webpackChunkName:'DTCActivePage'*/ "@/pages/DTCActive")
);

const LifeTimeDistance = lazy(() =>
  import(/*webpackChunkName:'LifeTimeDistancePage'*/ "@/pages/LifeTimeDistance")
);

const LifeTimeAge = lazy(() =>
  import(/*webpackChunkName:'LifeTimeAgePage'*/ "@/pages/LifeTimeAge")
);

const MaintenanceHistoryMonths = lazy(() =>
  import(
    /*webpackChunkName:'MaintenanceHistoryMonthsPage'*/ "@/pages/MaintenanceHistoryMonths"
  )
);

const MaintenanceHistoryKm = lazy(() =>
  import(
    /*webpackChunkName:'MaintenanceHistoryKmPage'*/ "@/pages/MaintenanceHistoryKm"
  )
);
const TotalDTC = lazy(() =>
  import(/*webpackChunkName:'TotalDTCPage'*/ "@/pages/TotalDTC")
);

const Items = lazy(() =>
  import(/*webpackChunkName:'ItemsPage'*/ "@/pages/Items")
);
const DTCCodes = lazy(() =>
  import(/*webpackChunkName:'DTCCodesPage'*/ "@/pages/DTCCodes")
);
const VehicleTypes = lazy(() =>
  import(/*webpackChunkName:'VehicleTypesPage'*/ "@/pages/VehicleTypes")
);

const DriverNotification = lazy(() =>
  import(
    /*webpackChunkName:'DriverNotificationPage'*/ "@/pages/DriverNotification"
  )
);

const VehicleNotification = lazy(() =>
  import(
    /*webpackChunkName:'VehicleNotificationPage'*/ "@/pages/VehicleNotification"
  )
);

const LicenseVehicle = lazy(() =>
  import(/*webpackChunkName:'LicenseDatePage'*/ "@/pages/LicenseVehicle")
);

const LicenseDriver = lazy(() =>
  import(/*webpackChunkName:'LicenseDatePage'*/ "@/pages/LicenseDriver")
);

const InsuranceDate = lazy(() =>
  import(/*webpackChunkName:'InsuranceDatePage'*/ "@/pages/InsuranceDate")
);

const SelectCustomer = lazy(() =>
  import(/*webpackChunkName:'SelectCustomerPage'*/ "@/pages/SelectCustomer")
);

const Lead = lazy(() => import(/*webpackChunkName:'LeadPage'*/ "@/pages/Lead"));
const Product = lazy(() =>
  import(/*webpackChunkName:'ProductPage'*/ "@/pages/Product")
);

const Logout = lazy(() =>
  import(/*webpackChunkName:'LogoutPage'*/ "@/pages/Logout")
);
const NotFound = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);

export default function AppRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/" component={Dashboard} exact />
          <PrivateRoute component={Customer} path="/customer" exact />
          <PrivateRoute component={SimCard} path="/simcard" exact />
          <PrivateRoute component={Device} path="/device" exact />
          <PrivateRoute component={Vehicle} path="/vehicle" exact />
          <PrivateRoute component={DailyScore} path="/daily-score" exact />
          <PrivateRoute
            component={LifeTimeScore}
            path="/lifetime-score"
            exact
          />
          <PrivateRoute
            component={DailyDistance}
            path="/daily-distance"
            exact
          />
          <PrivateRoute component={DailyTime} path="/daily-time" exact />
          <PrivateRoute component={DailyIdle} path="/daily-idle" exact />
          <PrivateRoute
            component={MaintenanceOverdueDays}
            path="/maintenance-over-days"
            exact
          />
          <PrivateRoute
            component={MaintenanceOverdueKm}
            path="/maintenance-over-km"
            exact
          />
          <PrivateRoute component={DTCActive} path="/dtc-active" exact />
          <PrivateRoute
            component={LifeTimeDistance}
            path="/lifetime-distance"
            exact
          />
          <PrivateRoute component={LifeTimeAge} path="/lifetime-age" exact />
          <PrivateRoute
            component={MaintenanceHistoryMonths}
            path="/maintenance-history-months"
            exact
          />
          <PrivateRoute
            component={MaintenanceHistoryKm}
            path="/maintenance-history-km"
            exact
          />
          <PrivateRoute component={TotalDTC} path="/total-dtc" exact />
          <PrivateRoute
            component={DriverNotification}
            path="/driver-notification"
            exact
          />
          <PrivateRoute
            component={VehicleNotification}
            path="/vehicle-notification"
            exact
          />
          <PrivateRoute component={Items} path="/items" exact />
          <PrivateRoute component={DTCCodes} path="/dtc-codes" exact />
          <PrivateRoute component={VehicleTypes} path="/vehicle-types" exact />
          <PrivateRoute component={LicenseVehicle} path="/license" exact />
          <PrivateRoute component={LicenseDriver} path="/renewal" exact />
          <PrivateRoute
            component={InsuranceDate}
            path="/insurance-date"
            exact
          />
          vehicle-types
          <PrivateRoute
            component={SelectCustomer}
            path="/selectcustomer"
            exact
          />
          {/*<PrivateRoute component={Lead} path="/lead" exact />
          <PrivateRoute component={Product} path="/product" exact />
          <PrivateRoute component={Admin} path="/admin" exact />*/}
          <PrivateRoute component={Logout} path="/logout" exact />
          <PublicRoute path="/login" render={() => <Redirect to="/" />} />
          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
