import React from "react";
import { Button, Form, Input, InputNumber } from "antd";

export default function MaintenanceOverdueDaysForm({ isUpdateForm = false }) {
  return (
    <>
      <Form.Item
        id="maintenance-overdue-days-FinalScore"
        label="Final Score"
        name="FinalScore"
        rules={[
          {
            required: true,
            message: "Please input Final Score!",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        id="maintenance-overdue-days-Equal"
        label="Equal"
        name="Equal"
        rules={[
          {
            required: true,
            message: "Please input Equal!",
          },
        ]}
      >
        <InputNumber max={0} />
      </Form.Item>

      <Form.Item
        id="maintenance-overdue-days-Min"
        label="Minimum Days"
        name="Min"
        rules={[
          {
            required: true,
            message: "Please input Minimum Days!",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Form.Item
        id="maintenance-overdue-days-Max"
        label="Maximum Days"
        name="Max"
        rules={[
          {
            required: true,
            message: "Please input Maximum Days!",
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
    </>
  );
}
