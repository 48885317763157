import React, { useState } from "react";
import toyotaLogo from "./All.png";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  SettingOutlined,
  UserOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  CreditCardOutlined,
  CarOutlined,
  EditOutlined,
  CalculatorOutlined,
  ControlOutlined,
  SlidersOutlined,
  UserAddOutlined,
  BoxPlotOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

function Navigation() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={"fit-content"}
        style={{
          zIndex: 1000,
        }}
      >
        <div
          className="logo"
          style={{
            backgroundImage: `url(${toyotaLogo})`,

            backgroundSize: "contain",
            backgroundPosition: "center",

            backgroundRepeat: "no-repeat",
          }}
        />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {/* <Menu.Item key="1" icon={<DashboardOutlined />}>
            <Link to="/" />
            Home Page
          </Menu.Item> */}
          <Menu.Item
            id="menu-item-customer"
            key="2"
            icon={<UsergroupAddOutlined />}
          >
            <Link to="/customer">Customer</Link>
          </Menu.Item>
          <Menu.Item
            id="menu-item-simcards"
            key="33"
            icon={<CreditCardOutlined />}
          >
            <Link to="/simcard" />
            Sim Card
          </Menu.Item>
          <Menu.Item id="menu-item-devices" key="44" icon={<BoxPlotOutlined />}>
            <Link to="/device" />
            Device
          </Menu.Item>
          <Menu.Item id="menu-item-vehicles" key="55" icon={<CarOutlined />}>
            <Link to="/vehicle" />
            Vehicle
          </Menu.Item>

          <SubMenu
            id="sub-menu-1"
            key="66"
            title="TFM Modification"
            icon={<EditOutlined />}
          >
            <SubMenu
              id="sub-menu-1.1"
              key="154"
              title="Scoring Weights"
              icon={<CalculatorOutlined />}
            >
              <Menu.Item id="sub-menu-item-1.1" key="130">
                Daily
                <Link to="/daily-score" />
              </Menu.Item>
              <Menu.Item id="sub-menu-item-1.2" key="120">
                Life Time
                <Link to="/lifetime-score" />
              </Menu.Item>
            </SubMenu>
            <SubMenu
              id="sub-menu-1.2"
              key="155"
              title="Control Values"
              icon={<ControlOutlined />}
            >
              <SubMenu id="sub-menu-1.2.1" key="155a" title="Daily">
                <SubMenu
                  id="sub-menu-1.2.1.1"
                  key="155aa"
                  title="Distance - Time"
                >
                  <Menu.Item id="sub-menu-item-1.2.1.1.1" key="155aaa">
                    Daily Distance
                    <Link to="/daily-distance" />
                  </Menu.Item>
                  <Menu.Item id="sub-menu-item-1.2.1.1.2" key="155aab">
                    Daily Time
                    <Link to="/daily-time" />
                  </Menu.Item>

                  <Menu.Item id="sub-menu-item-1.2.1.1.3" key="155aac">
                    Daily Idle
                    <Link to="/daily-idle" />
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  id="sub-menu-1.2.1.2"
                  key="155ab"
                  title="Maintenance Overdue"
                >
                  <Menu.Item id="sub-menu-item-1.2.1.2.1" key="155aba">
                    Maintenance Overdue Days
                    <Link to="/maintenance-over-days" />
                  </Menu.Item>
                  <Menu.Item id="sub-menu-item-1.2.1.2.2" key="155abb">
                    Maintenance Overdue Km
                    <Link to="/maintenance-over-km" />
                  </Menu.Item>
                </SubMenu>
                <Menu.Item id="sub-menu-item-1.2.1.1" key="155ac">
                  DTC Active
                  <Link to="/dtc-active" />
                </Menu.Item>
              </SubMenu>

              <SubMenu id="sub-menu-1.2.1.2.1" key="155b" title="LifeTime">
                <SubMenu
                  id="sub-menu-1.2.1.2.1.1"
                  key="155ba"
                  title="Distance - Age"
                >
                  <Menu.Item id="sub-menu-item-1.2.1.2.1.1" key="155baa">
                    LifeTime Distance
                    <Link to="/lifetime-distance" />
                  </Menu.Item>
                  <Menu.Item id="sub-menu-item-1.2.1.2.1.2" key="155bab">
                    LifeTime Age
                    <Link to="/lifetime-age" />
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  id="sub-menu-1.2.1.2.1.2"
                  key="155bb"
                  title="Maintenance History"
                >
                  <Menu.Item id="sub-menu-item-1.2.1.2.1.2.1" key="155bba">
                    Maintenance Months
                    <Link to="/maintenance-history-months" />
                  </Menu.Item>
                  <Menu.Item id="sub-menu-item-1.2.1.2.1.2.2" key="155bbb">
                    Maintenance History Km
                    <Link to="/maintenance-history-km" />
                  </Menu.Item>
                </SubMenu>
                <Menu.Item id="sub-menu-item-1.2.1.2.1.2.3" key="155bc">
                  Total DTC
                  <Link to="/total-dtc" />
                </Menu.Item>
              </SubMenu>
            </SubMenu>

            <Menu.Item id="menu-item-schedule-maintenance" key="88">
              Schedule Maintenance
            </Menu.Item>

            <Menu.Item id="menu-item-schedule-actions " key="99">
              Actions
            </Menu.Item>

            <SubMenu id="sub-menu-1.3" key="85" title="Notifications">
              <Menu.Item id="sub-menu-item-1.3.1" key="85a">
                Driver
                <Link to="/driver-notification" />
              </Menu.Item>
              <Menu.Item id="sub-menu-item-1.3.2" key="85b">
                Vehicle
                <Link to="/vehicle-notification" />
              </Menu.Item>
            </SubMenu>
            <SubMenu id="sub-menu-1.4" key="111" title="Settings">
              <Menu.Item id="sub-menu-item-1.4.2" key="111a">
                License vehicle
                <Link to="/license" />
              </Menu.Item>
              <Menu.Item id="sub-menu-item-1.4.2" key="111b">
                License driver
                <Link to="/renewal" />
              </Menu.Item>
              <Menu.Item id="sub-menu-item-1.4.2" key="111c">
                Insurance Date
                <Link to="/insurance-date" />
              </Menu.Item>
            </SubMenu>
            <Menu.Item id="sub-menu-item-1-dtc-codes" key="9878">
              DTC Codes
              <Link to="/dtc-codes" />
            </Menu.Item>
            <Menu.Item id="sub-menu-item-1-items" key="98">
              Items
              <Link to="/items" />
            </Menu.Item>
            <Menu.Item id="sub-menu-item-1-vehicle-types" key="999">
              Vehicle Types
              <Link to="/vehicle-types" />
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    </>
  );
}
export default Navigation;
