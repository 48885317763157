export const API_BASE_URL =
  process.env.REACT_APP_NODE_ENV == "production" ||
  process.env.REACT_APP_DEV_REMOTE == "remote"
    ? process.env.REACT_APP_API_BASE_URL_PRODUCTION
    : process.env.REACT_APP_API_BASE_URL_LOCAL;
export const ACCESS_TOKEN_NAME = "Authorization";
export const FUSIONAUTH_URL = process.env.REACT_APP_FUSIONAUTH_URL;
export const FUSIONAUTH_API_KEY = process.env.REACT_APP_FUSIONAUTH_API_KEY;
export const FUSIONAUTH_APPLICATION_ID =
  process.env.REACT_APP_FUSIONAUTH_APPLICATION_ID;
